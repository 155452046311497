import React, { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Text,
  Link,
  View,
  defaultTheme,
  Provider,
} from "@adobe/react-spectrum";

const Privacy: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(matchMedia.matches);

    const handleChange = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches);
    };

    matchMedia.addEventListener("change", handleChange);
    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, []);

  const headerTextColor = isDarkMode ? "#0a7f5a" : "#059669";
  return (
    <Provider theme={defaultTheme}>
      <View
        UNSAFE_style={{
          backgroundColor: "var(--background-color)",
          color: "var(--text-color)",
          padding: "var(--spectrum-global-dimension-size-200)",
        }}
      >
        <Flex direction="column" alignItems="center" justifyContent="center">
          <View
            UNSAFE_style={{
              backgroundColor: headerTextColor,
              padding: "var(--spectrum-global-dimension-size-200)",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Heading level={1} UNSAFE_style={{ color: "white", margin: 0 }}>
              Privacy Policy
            </Heading>
          </View>
          <View
            UNSAFE_style={{
              maxWidth: "800px",
              marginTop: "var(--spectrum-global-dimension-size-200)",
              marginBottom: "var(--spectrum-global-dimension-size-200)",
              backgroundColor: "var(--background-color)",
              padding: "var(--spectrum-global-dimension-size-300)",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "var(--spectrum-global-dimension-size-200)",
            }}
          >
            <View marginBottom={"size-200"}>
              <Text>
                <strong>Last Updated:</strong> January 7th, 2025
              </Text>
            </View>

            <View>
              <Text>
                We are committed to protecting your privacy and ensuring that
                your personal information is handled responsibly. This Privacy
                Policy explains what information we collect, how we use it, your
                rights regarding your data, and our compliance with applicable
                laws, including U.S. regulations.
              </Text>
            </View>

            <Heading level={2}>1. Information We Collect</Heading>
            <Text>
              We collect the following information to provide and improve our
              services:
            </Text>
            <ul>
              <li>
                <Text>
                  <strong>Email:</strong> Collected during sign-up and sign-in
                  to create and manage your account.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Email Verification Status:</strong> Tracked to ensure
                  your account is valid and secure.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Locale:</strong> Collected to offer services in your
                  preferred language.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Address, Latitude, and Longitude:</strong> Temporarily
                  stored in your browser’s sessionStorage for location-based
                  services. This information is deleted when the browser tab is
                  closed.
                </Text>
              </li>
            </ul>
            <Text>
              When you use Google authentication (OAuth 2.0) to sign in, Google
              provides us with limited information, such as your email, to
              verify your identity. You can manage your data permissions in your
              Google account settings.
            </Text>

            <Heading level={2}>2. How We Use the Information</Heading>
            <Text>
              We use the information collected for the following purposes:
            </Text>
            <ul>
              <li>
                <Text>
                  <strong>Account Creation and Authentication:</strong> Your
                  email and verification status are used for secure account
                  setup and sign-in.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Personalization:</strong> Your locale is used to
                  deliver services in your preferred language.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Location-Based Services:</strong> Address and location
                  data (latitude and longitude) are stored temporarily in
                  sessionStorage to provide location-based features. This data
                  is automatically deleted once the browser tab is closed.
                </Text>
              </li>
            </ul>

            <Heading level={2}>3. No Cookies and Tracking Technologies</Heading>
            <Text>
              We do not use cookies or other tracking technologies. Instead, we
              use sessionStorage to store certain temporary data, such as your
              location, to facilitate location-based services.
            </Text>

            <Heading level={2}>4. How We Share Your Information</Heading>
            <Text>
              We respect your privacy and do not share your personal information
              with third parties, advertisers, or for business transfers. Your
              data is used strictly within our systems to provide the services
              you request.
            </Text>

            <Heading level={2}>5. Your Rights Under U.S. Law</Heading>
            <Text>
              In compliance with applicable U.S. laws, including the California
              Consumer Privacy Act (CCPA) and other state laws, you have the
              right to:
            </Text>
            <ul>
              <li>
                <Text>Access the personal information we hold about you.</Text>
              </li>
              <li>
                <Text>
                  Request Corrections if any information is inaccurate.
                </Text>
              </li>
              <li>
                <Text>Request Deletion of your personal information.</Text>
              </li>
            </ul>
            <Text>
              If you wish to delete your data or exercise your rights, please
              email us at{" "}
              <Link>
                <a href="mailto:shopplanner07@gmail.com">
                  shopplanner07@gmail.com
                </a>
              </Link>
              , and we will process your request. We will respond to such
              requests within 45 days as required by law.
            </Text>

            <Heading level={2}>6. Data Security</Heading>
            <Text>
              We implement reasonable security measures to protect your data,
              including encryption and secure communication protocols. Sensitive
              data obtained from OAuth 2.0 authentication, such as tokens, is
              handled securely and stored only as long as necessary to complete
              authentication. However, no system is completely secure, and we
              cannot guarantee the absolute safety of your information.
            </Text>

            <Heading level={2}>7. Data Retention</Heading>
            <Text>
              We retain personal data for as long as it is necessary to provide
              our services or fulfill the purposes outlined in this Privacy
              Policy. Data will not be automatically deleted and will remain
              stored unless:
            </Text>
            <ul>
              <li>
                <Text>
                  You request deletion of your data by contacting us at{" "}
                  <Link>
                    <a href="mailto:shopplanner07@gmail.com">
                      shopplanner07@gmail.com
                    </a>
                  </Link>
                  .
                </Text>
              </li>
              <li>
                <Text>
                  We are required to delete it due to legal obligations.
                </Text>
              </li>
            </ul>
            <Text>
              We take measures to ensure that your data is securely stored and
              accessible only to authorized personnel. Upon request for
              deletion, we will promptly remove your data in compliance with
              applicable laws, including the California Consumer Privacy Act
              (CCPA) if applicable.
            </Text>

            <Heading level={2}>8. Children’s Privacy</Heading>
            <Text>
              Our services are not intended for children under the age of 13, in
              compliance with the Children's Online Privacy Protection Act
              (COPPA). We do not knowingly collect personal information from
              children. If we discover such a collection, we will delete the
              data promptly. Parents or guardians may contact us at{" "}
              <Link>
                <a href="mailto:shopplanner07@gmail.com">
                  shopplanner07@gmail.com
                </a>
              </Link>{" "}
              if they suspect unauthorized data collection.
            </Text>

            <Heading level={2}>9. Educational Purpose Disclaimer</Heading>
            <Text>
              The services and information provided are for educational purposes
              only. We do not guarantee that the content or services comply with
              any legal, professional, or regulatory requirements in your
              jurisdiction. By using our services, you acknowledge that we are
              not responsible or liable for any legal issues that may arise from
              the use of our platform, and you agree to use the information at
              your own discretion.
            </Text>

            <Heading level={2}>10. Changes to This Privacy Policy</Heading>
            <Text>
              We may update this Privacy Policy from time to time. Significant
              updates will be communicated via email or through our app. The
              most recent version will always be available on our website.
            </Text>

            <Heading level={2}>11. Contact Us</Heading>
            <Text>
              If you have any questions about this Privacy Policy, wish to
              request the deletion of your data, or have concerns regarding our
              compliance with U.S. law, please contact us at:
            </Text>
            <Text>
              Email:{" "}
              <Link>
                <a href="mailto:shopplanner07@gmail.com">
                  shopplanner07@gmail.com
                </a>
              </Link>
            </Text>
          </View>
          <View
            UNSAFE_style={{
              backgroundColor: "var(--footer-background-color)",
              padding: "var(--spectrum-global-dimension-size-200)",
              width: "100%",
              marginTop: "var(--spectrum-global-dimension-size-200)",
              textAlign: "center",
            }}
          >
            <Text UNSAFE_style={{ color: "var(--footer-text-color)" }}>
              &copy; 2024 Shoping Planner. All rights reserved.
            </Text>
          </View>
        </Flex>
      </View>
    </Provider>
  );
};

export default Privacy;
