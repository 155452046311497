import React, { useEffect, useState } from "react";
import {
  Flex,
  Heading,
  Text,
  Link,
  View,
  defaultTheme,
  Provider,
} from "@adobe/react-spectrum";

const Terms: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(matchMedia.matches);

    const handleChange = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches);
    };

    matchMedia.addEventListener("change", handleChange);
    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, []);

  const headerTextColor = isDarkMode ? "#0a7f5a" : "#059669";
  return (
    <Provider theme={defaultTheme}>
      <View
        UNSAFE_style={{
          backgroundColor: "var(--background-color)",
          color: "var(--text-color)",
          padding: "var(--spectrum-global-dimension-size-200)",
        }}
      >
        <Flex direction="column" alignItems="center" justifyContent="center">
          <View
            UNSAFE_style={{
              backgroundColor: headerTextColor,
              padding: "var(--spectrum-global-dimension-size-200)",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Heading level={1} UNSAFE_style={{ color: "white", margin: 0 }}>
              Terms of Service
            </Heading>
          </View>
          <View
            UNSAFE_style={{
              maxWidth: "800px",
              marginTop: "var(--spectrum-global-dimension-size-200)",
              marginBottom: "var(--spectrum-global-dimension-size-200)",
              backgroundColor: "var(--background-color)",
              padding: "var(--spectrum-global-dimension-size-300)",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "var(--spectrum-global-dimension-size-200)",
            }}
          >
            <View marginBottom={"size-200"}>
              <Text>
                <strong>Last Updated:</strong> January 7th, 2025
              </Text>
            </View>
            <Text>
              Welcome to Shoping Planner! By accessing or using our services,
              you agree to comply with and be bound by these Terms of Service
              ("Terms"). Please read them carefully, as they constitute a legal
              agreement between you and the individual(s) responsible for
              Shoping Planner.
            </Text>

            <Heading level={2}>1. Acceptance of Terms</Heading>
            <Text>
              By using our service, you agree to these Terms and any associated
              policies, including our Privacy Policy. If you do not agree to any
              part of these Terms, you must discontinue use of the service
              immediately.
            </Text>

            <Heading level={2}>2. Eligibility</Heading>
            <Text>
              You must be at least 13 years old to use our services. By using
              the service, you confirm that you meet this age requirement. If
              you are under 13, you are prohibited from creating an account or
              using this service in compliance with the Children’s Online
              Privacy Protection Act (COPPA). We do not knowingly collect or
              store any personal information from users under 13.
            </Text>

            <Heading level={2}>3. User Accounts</Heading>
            <ul>
              <li>
                <Text>
                  <strong>Account Creation:</strong> You may be required to
                  create an account to access certain features. You agree to
                  provide accurate and up-to-date information when registering
                  and to keep your login credentials confidential.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Account Security:</strong> You are responsible for any
                  activity under your account. If you suspect unauthorized
                  access, please notify us immediately. You may not share or
                  transfer your account to others.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Google OAuth:</strong> By using Google OAuth for login
                  or account creation, you agree to Google’s Terms of Service
                  and Privacy Policy. You authorize us to access certain
                  information from your Google account (e.g., email, profile
                  information) solely for authentication and access purposes. We
                  collect only the necessary information required to provide our
                  services. We do not share or sell this data to third parties,
                  except as required by law.
                </Text>
              </li>
            </ul>

            <Heading level={2}>4. Google OAuth Privacy and Data Usage</Heading>
            <Text>
              When using Google OAuth to sign up or log in, we collect only your
              Google email and profile name for authentication purposes. This
              information is used solely for creating and managing your Shoping
              Planner account.
            </Text>
            <ul>
              <li>
                <Text>
                  <strong>Data Retention and Deletion:</strong> We retain your
                  Google OAuth data as long as your account remains active or as
                  necessary to provide the service. Upon account deletion or
                  request, we delete your data promptly in compliance with
                  applicable laws, including the California Consumer Privacy Act
                  (CCPA) if applicable.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Data Security:</strong> We implement industry-standard
                  security practices to protect your Google OAuth data from
                  unauthorized access, disclosure, or misuse.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>User Rights and Revocation:</strong> You may request
                  deletion of your account or personal data by contacting us at{" "}
                  <Link href="mailto:shopplanner07@gmail.com">
                    shopplanner07@gmail.com
                  </Link>
                  . You may also revoke Shoping Planner’s access through your
                  Google Account settings at any time. We will respond to such
                  requests in accordance with applicable data protection laws.
                </Text>
              </li>
            </ul>
            <Text>
              Our use of Google OAuth is in compliance with Google’s OAuth
              policies and API usage guidelines. We are committed to handling
              your data securely and transparently.
            </Text>

            <Heading level={2}>5. Use of the Service</Heading>
            <Text>
              You agree to use our services lawfully and respectfully. The
              following activities are prohibited:
            </Text>
            <ul>
              <li>
                <Text>
                  Engaging in any illegal activity or violating any applicable
                  U.S. laws.
                </Text>
              </li>
              <li>
                <Text>
                  Attempting to reverse-engineer, decompile, or modify the
                  service.
                </Text>
              </li>
              <li>
                <Text>
                  Using the service to harass, defame, or harm others.
                </Text>
              </li>
              <li>
                <Text>
                  Scraping, crawling, or using automated methods to collect data
                  from the service.
                </Text>
              </li>
            </ul>
            <Text>
              We reserve the right to suspend or terminate your access to the
              service if we find that you are in violation of these Terms.
            </Text>

            <Heading level={2}>6. User-Generated Content</Heading>
            <ul>
              <li>
                <Text>
                  <strong>Content Rules:</strong> Users are responsible for any
                  content they upload, post, or share (e.g., comments, photos).
                  All user-generated content must comply with our community
                  guidelines and must not contain any unlawful, offensive, or
                  harmful material.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>License to Use:</strong> By uploading or posting
                  content, you grant us a non-exclusive, royalty-free license to
                  use, distribute, and display that content for the purpose of
                  operating the service.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Content Removal:</strong> We reserve the right to
                  remove any content that violates these Terms, U.S. law, or
                  infringes on the rights of others.
                </Text>
              </li>
            </ul>

            <Heading level={2}>7. Payments and Subscriptions</Heading>
            <Text>
              Note: Currently, Shoping Planner does not offer any paid services,
              subscriptions, or in-app purchases. If we introduce any in the
              future, this section will be updated with the applicable billing
              terms, refund policy, and subscription renewal information.
            </Text>

            <Heading level={2}>8. Intellectual Property</Heading>
            <Text>
              All content, trademarks, service marks, and intellectual property
              on this platform are owned by the individual(s) who created
              Shoping Planner. You are granted a limited, non-transferable, and
              revocable license to access and use the service for personal or
              educational purposes. Any unauthorized reproduction or
              redistribution is prohibited under U.S. intellectual property
              laws.
            </Text>

            <Heading level={2}>9. Termination</Heading>
            <Text>
              We reserve the right to suspend or terminate your account at any
              time for any breach of these Terms or any misuse of the service.
              Upon termination, your right to access the service will
              immediately cease. You may also request account termination by
              contacting us at{" "}
              <Link href="mailto:shopplanner07@gmail.com">
                shopplanner07@gmail.com
              </Link>
              .
            </Text>

            <Heading level={2}>
              10. Disclaimers and Limitation of Liability
            </Heading>
            <ul>
              <li>
                <Text>
                  <strong>Service Disclaimer:</strong> Our service is provided
                  on an "as is" and "as available" basis. We make no warranties,
                  express or implied, regarding the reliability, accuracy, or
                  availability of the service. We disclaim all warranties to the
                  fullest extent permitted by U.S. law, including warranties of
                  merchantability, fitness for a particular purpose, and
                  non-infringement.
                </Text>
              </li>
              <li>
                <Text>
                  <strong>Limitation of Liability:</strong> To the extent
                  allowed by law, we are not liable for any direct, indirect,
                  incidental, special, or consequential damages that result from
                  your use of the service, including but not limited to data
                  loss, unauthorized access, or financial losses.
                </Text>
              </li>
            </ul>
            <Text>
              Some states do not allow the exclusion or limitation of liability
              for certain damages. In such states, our liability is limited to
              the maximum extent permitted by law.
            </Text>

            <Heading level={2}>11. Indemnification</Heading>
            <Text>
              You agree to indemnify and hold harmless the individual(s)
              responsible for Shoping Planner from any claims, damages, or
              expenses arising out of your use of the service, your violation of
              these Terms, or your violation of any rights of others, including
              any intellectual property or privacy rights.
            </Text>

            <Heading level={2}>
              12. Governing Law and Dispute Resolution
            </Heading>
            <Text>
              These Terms are governed by and construed in accordance with the
              laws of the United States and the State of Washington, without
              regard to conflict of law principles. You agree to submit to the
              exclusive jurisdiction of the state and federal courts located in
              Washington State for any disputes arising from or related to your
              use of the service.
            </Text>
            <ul>
              <li>
                <Text>
                  <strong>Arbitration Clause:</strong> In the event of a dispute
                  between you and Shoping Planner, you agree to first attempt to
                  resolve the dispute informally by contacting us at{" "}
                  <Link href="mailto:shopplanner07@gmail.com">
                    shopplanner07@gmail.com
                  </Link>
                  . If the dispute cannot be resolved informally, it will be
                  settled through binding arbitration in Washington State under
                  the rules of the American Arbitration Association (AAA). Each
                  party will bear its own costs in arbitration.
                </Text>
              </li>
            </ul>

            <Heading level={2}>13. Changes to Terms</Heading>
            <Text>
              We reserve the right to modify these Terms at any time. When
              changes are made, we will notify you via email or within the
              service. By continuing to use the service after such changes, you
              agree to be bound by the updated Terms. The most recent version of
              the Terms will always be available on our website or app.
            </Text>

            <Heading level={2}>14. Educational Purpose Disclaimer</Heading>
            <Text>
              Our service is provided for educational purposes only. We do not
              guarantee the accuracy, completeness, or legality of the
              information provided. By using the service, you acknowledge that
              the individual(s) responsible for Shoping Planner are not
              responsible for any legal, financial, or other outcomes related to
              the use of the content or services.
            </Text>

            <Heading level={2}>15. Contact Information</Heading>
            <Text>
              If you have any questions about these Terms or need to contact us
              regarding your account or rights, please reach out to us at:
            </Text>
            <Text>
              Email:{" "}
              <Link href="mailto:shopplanner07@gmail.com">
                shopplanner07@gmail.com
              </Link>
            </Text>
          </View>
          <View
            UNSAFE_style={{
              backgroundColor: "var(--footer-background-color)",
              padding: "var(--spectrum-global-dimension-size-200)",
              width: "100%",
              marginTop: "var(--spectrum-global-dimension-size-200)",
              textAlign: "center",
            }}
          >
            <Text UNSAFE_style={{ color: "var(--footer-text-color)" }}>
              &copy; 2024 Shoping Planner. All rights reserved.
            </Text>
          </View>
        </Flex>
      </View>
    </Provider>
  );
};

export default Terms;
